import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SurveyModel } from '../survey-table/models/survey-model';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { CountrySurveyModel } from './models/country-survey-model';
import { ProviderService } from '../../core/provider.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SurveyStatusEnum } from './enums/survey-status-enum';
import { SurveyValidationStatusEnum } from './enums/survey-validation-status-enum';
import { CustomDownloadSnackbarComponent } from '../dialogues/snackbars/custom-download-snackbar/custom-download-snackbar.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-survey-country-table',
  templateUrl: './survey-country-table.component.html',
  styleUrl: './survey-country-table.component.scss',
})
export class SurveyCountryTableComponent {
  @Input({ required: true }) selectedSurvey!: SurveyModel;
  @Output() countrySelected = new EventEmitter<CountrySurveyModel>();
  @ViewChild(MatPaginator)
  set paginator(value: MatPaginator) {
    if (this.dataSource) {
      this.dataSource.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
      this.dataSource.sortingDataAccessor = (item: any, property: any) => {
        const columnName = property;
        if (item[columnName]) {
          return item[columnName].toString().toLowerCase();
        }
      };
    }
  }
  protected readonly SurveyStatusEnum = SurveyStatusEnum;
  protected readonly SurveyValidationStatusEnum = SurveyValidationStatusEnum;
  loading = true;
  filterCountrySurveyFormGroup = new FormGroup({
    isoCode: new FormControl(''),
    countryName: new FormControl(''),
    surveyStatus: new FormControl(null),
    validationStatus: new FormControl(null),
  });
  dataSource!: MatTableDataSource<CountrySurveyModel>;
  displayedColumns = [
    'isoCode',
    'countryName',
    'surveyStatus',
    'validationStatus',
    'actions',
  ];
  downloadingPDF = false;
  private downloadDialog: any;
  private selectedDownloadCountries: string[] = [];

  constructor(
    private providerService: ProviderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.dataSource = new MatTableDataSource<CountrySurveyModel>();
  }

  ngOnInit(): void {
    this.initiateTable();
  }

  applyFilter(): void {
    const filtersValue = {
      countryName:
        this.filterCountrySurveyFormGroup.controls.countryName.value?.toLowerCase(),
      isoCode:
        this.filterCountrySurveyFormGroup.controls.isoCode.value?.toLowerCase(),
      surveyStatus:
        this.filterCountrySurveyFormGroup.controls.surveyStatus.value,
      validationStatus:
        this.filterCountrySurveyFormGroup.controls.validationStatus.value,
    };
    this.dataSource.filterPredicate = (data, filterString: any) => {
      const filters = JSON.parse(filterString);
      return (
        data.countryName.toLowerCase().includes(filters.countryName) &&
        data.isoCode.toLowerCase().includes(filters.isoCode) &&
        (!filters.surveyStatus ||
          (filters.surveyStatus &&
            data.surveyStatus === filters.surveyStatus)) &&
        (!filters.validationStatus ||
          (filters.validationStatus &&
            data.validationStatus === filters.validationStatus))
      );
    };
    this.dataSource.filter = JSON.stringify(filtersValue);
  }

  get tableCount(): number {
    return this.dataSource.data.length;
  }

  private async initiateTable(): Promise<void> {
    this.loading = true;
    try {
      this.dataSource.data =
        await this.providerService.surveyCountryTableService.getCountryList(
          this.selectedSurvey.surveyID
        );
    } catch (e) {
      console.error(e);
      this.snackBar.open(
        'An error occured while retrieving the country list',
        'X',
        {
          duration: 3000,
          panelClass: ['error-snackbar'],
        }
      );
    } finally {
      this.loading = false;
    }
  }

  selectCountry(country: CountrySurveyModel): void {
    this.countrySelected.emit(country);
  }

  async startSurveyValidation(countryID: string): Promise<void> {
    await this.providerService.surveyCountryTableService.startCountryValidation(
      this.selectedSurvey.surveyID,
      countryID
    );
    this.initiateTable();
  }

  showDownloadPDFDialog(dialogRef: any): void {
    this.downloadDialog = this.dialog.open(dialogRef, {
      width: '500px',
      disableClose: true,
      autoFocus: false,
    });
  }

  async downloadPDF(): Promise<void> {
    this.downloadingPDF = true;
    try {
      await this.providerService.countryValidationTableService.downloadPDF(
        this.selectedSurvey.surveyID,
        this.selectedDownloadCountries
      );
      this.selectedDownloadCountries = [];
      this.downloadDialog.close();
      this.snackBar.openFromComponent(CustomDownloadSnackbarComponent, {
        duration: 10000,
        panelClass: ['custom-download-snackbar'],
      });
      this.downloadDialog?.close();
    } catch (e) {
      console.error(e);
      this.snackBar.open('An error occured while downloading the file', 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    } finally {
      this.downloadingPDF = false;
    }
  }

  selectDownloadCountries($event: string[]): void {
    this.selectedDownloadCountries = $event;
  }
}
