import { Injectable } from '@angular/core';
import { NetworkService } from 'src/app/core/net-utils/network.service';
import { ValidationModuleModel } from '../models/validation-module-model';

@Injectable({
  providedIn: 'root',
})
export class ValidationModuleService {
  private networkService!: NetworkService;

  setNetworkService(networkService: NetworkService): void {
    this.networkService = networkService;
  }

  async getValidationModuleTableCount(
    _surveyID: string,
    _countryID: string[],
    _code: string[],
    _fromYear: number,
    _toYear: number
  ): Promise<number> {
    return 20;
  }

  async getValidationModuleTableList(
    _surveyID: string,
    _countryID: string[],
    _code: string[],
    _fromYear: number,
    _toYear: number,
    _sortCol: string,
    _sortType: string,
    _pageNumber: number,
    _pageSize: number
  ): Promise<ValidationModuleModel[]> {
    return [
      {
        countryID: '8f57f8d1-2b1b-4e3c-9a8f-3a7b5e9c8d6a',
        countryName: 'Italy',
        countryISO: 'ITA',
        years: [
          {
            dataID: '8f47e8c1-2b1a-4e5c-9d8f-3a7b5e9c8d6a',
            year: 2020,
            value: '10000',
          },
          {
            dataID: 'a1b2c3d4-e5f6-4g7h-8i9j-0k1l2m3n4o5p',
            year: 2021,
            value: '10000',
          },
          {
            dataID: 'b2c3d4e5-f6g7-4h8i-9j0k-1l2m3n4o5p6q',
            year: 2022,
            value: '10000',
          },
        ],
      },
      {
        countryID: '9a68g9e2-3c2c-5f4d-0b9g-4b8c6f0d9e7b',
        countryName: 'France',
        countryISO: 'FRA',
        years: [
          {
            dataID: 'c3d4e5f6-g7h8-5i9j-0k1l-2m3n4o5p6q7r',
            year: 2020,
            value: '15000',
          },
          {
            dataID: 'd4e5f6g7-h8i9-6j0k-1l2m-3n4o5p6q7r8s',
            year: 2021,
            value: '16000',
          },
          {
            dataID: 'e5f6g7h8-i9j0-7k1l-2m3n-4o5p6q7r8s9t',
            year: 2022,
            value: '17000',
          },
        ],
      },
      {
        countryID: 'b7c9h0f3-4d3d-6g5e-1c0h-5c9d7g1e0f8c',
        countryName: 'Germany',
        countryISO: 'DEU',
        years: [
          {
            dataID: 'f6g7h8i9-j0k1-8l2m-3n4o-5p6q7r8s9t0u',
            year: 2020,
            value: '20000',
          },
          {
            dataID: 'g7h8i9j0-k1l2-9m3n-4o5p-6q7r8s9t0u1v',
            year: 2021,
            value: '21000',
          },
          {
            dataID: 'h8i9j0k1-l2m3-0n4o-5p6q-7r8s9t0u1v2w',
            year: 2022,
            value: '22000',
          },
        ],
      },
      {
        countryID: 'c8d0i1g4-5e4e-7h6f-2d1i-6d0e8h2f1g9d',
        countryName: 'Spain',
        countryISO: 'ESP',
        years: [
          {
            dataID: 'i9j0k1l2-m3n4-1o5p-6q7r-8s9t0u1v2w3x',
            year: 2020,
            value: '12000',
          },
          {
            dataID: 'j0k1l2m3-n4o5-2p6q-7r8s-9t0u1v2w3x4y',
            year: 2021,
            value: '13000',
          },
          {
            dataID: 'k1l2m3n4-o5p6-3q7r-8s9t-0u1v2w3x4y5z',
            year: 2022,
            value: '14000',
          },
        ],
      },
      {
        countryID: 'd9e1j2h5-6f5f-8i7g-3e2j-7e1f9i3g2h0e',
        countryName: 'Portugal',
        countryISO: 'PRT',
        years: [
          {
            dataID: 'l2m3n4o5-p6q7-4r8s-9t0u-1v2w3x4y5z6a',
            year: 2020,
            value: '8000',
          },
          {
            dataID: 'm3n4o5p6-q7r8-5s9t-0u1v-2w3x4y5z6a7b',
            year: 2021,
            value: '8500',
          },
          {
            dataID: 'n4o5p6q7-r8s9-6t0u-1v2w-3x4y5z6a7b8c',
            year: 2022,
            value: '9000',
          },
        ],
      },
      {
        countryID: 'e0f2k3i6-7g6g-9j8h-4f3k-8f2g0j4h3i1f',
        countryName: 'Netherlands',
        countryISO: 'NLD',
        years: [
          {
            dataID: 'o5p6q7r8-s9t0-7u1v-2w3x-4y5z6a7b8c9d',
            year: 2020,
            value: '18000',
          },
          {
            dataID: 'p6q7r8s9-t0u1-8v2w-3x4y-5z6a7b8c9d0e',
            year: 2021,
            value: '19000',
          },
          {
            dataID: 'q7r8s9t0-u1v2-9w3x-4y5z-6a7b8c9d0e1f',
            year: 2022,
            value: '20000',
          },
        ],
      },
      {
        countryID: 'f1g3l4j7-8h7h-0k9i-5g4l-9g3h1k5i4j2g',
        countryName: 'Belgium',
        countryISO: 'BEL',
        years: [
          {
            dataID: 'r8s9t0u1-v2w3-0x4y-5z6a-7b8c9d0e1f2g',
            year: 2020,
            value: '11000',
          },
          {
            dataID: 's9t0u1v2-w3x4-1y5z-6a7b-8c9d0e1f2g3h',
            year: 2021,
            value: '11500',
          },
          {
            dataID: 't0u1v2w3-x4y5-2z6a-7b8c-9d0e1f2g3h4i',
            year: 2022,
            value: '12000',
          },
        ],
      },
      {
        countryID: 'g2h4m5k8-9i8i-1l0j-6h5m-0h4i2l6j5k3h',
        countryName: 'Sweden',
        countryISO: 'SWE',
        years: [
          {
            dataID: 'u1v2w3x4-y5z6-3a7b-8c9d-0e1f2g3h4i5j',
            year: 2020,
            value: '16000',
          },
          {
            dataID: 'v2w3x4y5-z6a7-4b8c-9d0e-1f2g3h4i5j6k',
            year: 2021,
            value: '17000',
          },
          {
            dataID: 'w3x4y5z6-a7b8-5c9d-0e1f-2g3h4i5j6k7l',
            year: 2022,
            value: '18000',
          },
        ],
      },
      {
        countryID: 'h3i5n6l9-0j9j-2m1k-7i6n-1i5j3m7k6l4i',
        countryName: 'Denmark',
        countryISO: 'DNK',
        years: [
          {
            dataID: 'x4y5z6a7-b8c9-6d0e-1f2g-3h4i5j6k7l8m',
            year: 2020,
            value: '14000',
          },
          {
            dataID: 'y5z6a7b8-c9d0-7e1f-2g3h-4i5j6k7l8m9n',
            year: 2021,
            value: '15000',
          },
          {
            dataID: 'z6a7b8c9-d0e1-8f2g-3h4i-5j6k7l8m9n0o',
            year: 2022,
            value: '16000',
          },
        ],
      },
      {
        countryID: 'i4j6o7m0-1k0k-3n2l-8j7o-2j6k4n8l7m5j',
        countryName: 'Finland',
        countryISO: 'FIN',
        years: [
          {
            dataID: 'a7b8c9d0-e1f2-9g3h-4i5j-6k7l8m9n0o1p',
            year: 2020,
            value: '13000',
          },
          {
            dataID: 'b8c9d0e1-f2g3-0h4i-5j6k-7l8m9n0o1p2q',
            year: 2021,
            value: '14000',
          },
          {
            dataID: 'c9d0e1f2-g3h4-1i5j-6k7l-8m9n0o1p2q3r',
            year: 2022,
            value: '15000',
          },
        ],
      },
      {
        countryID: 'j5k7p8n1-2l1l-4o3m-9k8p-3k7l5o9m8n6k',
        countryName: 'Norway',
        countryISO: 'NOR',
        years: [
          {
            dataID: 'd0e1f2g3-h4i5-2j6k-7l8m-9n0o1p2q3r4s',
            year: 2020,
            value: '19000',
          },
          {
            dataID: 'e1f2g3h4-i5j6-3k7l-8m9n-0o1p2q3r4s5t',
            year: 2021,
            value: '20000',
          },
          {
            dataID: 'f2g3h4i5-j6k7-4l8m-9n0o-1p2q3r4s5t6u',
            year: 2022,
            value: '21000',
          },
        ],
      },
      {
        countryID: 'k6l8q9o2-3m2m-5p4n-0l9q-4l8m6p0n9o7l',
        countryName: 'Greece',
        countryISO: 'GRC',
        years: [
          {
            dataID: 'g3h4i5j6-k7l8-5m9n-0o1p-2q3r4s5t6u7v',
            year: 2020,
            value: '9000',
          },
          {
            dataID: 'h4i5j6k7-l8m9-6n0o-1p2q-3r4s5t6u7v8w',
            year: 2021,
            value: '9500',
          },
          {
            dataID: 'i5j6k7l8-m9n0-7o1p-2q3r-4s5t6u7v8w9x',
            year: 2022,
            value: '10000',
          },
        ],
      },
      {
        countryID: 'l7m9r0p3-4n3n-6q5o-1m0r-5m9n7q1o0p8m',
        countryName: 'Austria',
        countryISO: 'AUT',
        years: [
          {
            dataID: 'j6k7l8m9-n0o1-8p2q-3r4s-5t6u7v8w9x0y',
            year: 2020,
            value: '17000',
          },
          {
            dataID: 'k7l8m9n0-o1p2-9q3r-4s5t-6u7v8w9x0y1z',
            year: 2021,
            value: '18000',
          },
          {
            dataID: 'l8m9n0o1-p2q3-0r4s-5t6u-7v8w9x0y1z2a',
            year: 2022,
            value: '19000',
          },
        ],
      },
      {
        countryID: 'm8n0s1q4-5o4o-7r6p-2n1s-6n0o8r2p1q9n',
        countryName: 'Ireland',
        countryISO: 'IRL',
        years: [
          {
            dataID: 'm9n0o1p2-q3r4-1s5t-6u7v-8w9x0y1z2a3b',
            year: 2020,
            value: '15000',
          },
          {
            dataID: 'n0o1p2q3-r4s5-2t6u-7v8w-9x0y1z2a3b4c',
            year: 2021,
            value: '16000',
          },
          {
            dataID: 'o1p2q3r4-s5t6-3u7v-8w9x-0y1z2a3b4c5d',
            year: 2022,
            value: '17000',
          },
        ],
      },
      {
        countryID: 'n9o1t2r5-6p5p-8s7q-3o2t-7o1p9s3q2r0o',
        countryName: 'Poland',
        countryISO: 'POL',
        years: [
          {
            dataID: 'p2q3r4s5-t6u7-4v8w-9x0y-1z2a3b4c5d6e',
            year: 2020,
            value: '11000',
          },
          {
            dataID: 'q3r4s5t6-u7v8-5w9x-0y1z-2a3b4c5d6e7f',
            year: 2021,
            value: '12000',
          },
          {
            dataID: 'r4s5t6u7-v8w9-6x0y-1z2a-3b4c5d6e7f8g',
            year: 2022,
            value: '13000',
          },
        ],
      },
      {
        countryID: 'o0p2u3s6-7q6q-9t8r-4p3u-8p2q0t4r3s1p',
        countryName: 'Hungary',
        countryISO: 'HUN',
        years: [
          {
            dataID: 's5t6u7v8-w9x0-7y1z-2a3b-4c5d6e7f8g9h',
            year: 2020,
            value: '8000',
          },
          {
            dataID: 't6u7v8w9-x0y1-8z2a-3b4c-5d6e7f8g9h0i',
            year: 2021,
            value: '8500',
          },
          {
            dataID: 'u7v8w9x0-y1z2-9a3b-4c5d-6e7f8g9h0i1j',
            year: 2022,
            value: '9000',
          },
        ],
      },
      {
        countryID: 'p1q3v4t7-8r7r-0u9s-5q4v-9q3r1u5s4t2q',
        countryName: 'Czech Republic',
        countryISO: 'CZE',
        years: [
          {
            dataID: 'v8w9x0y1-z2a3-0b4c-5d6e-7f8g9h0i1j2k',
            year: 2020,
            value: '10000',
          },
          {
            dataID: 'w9x0y1z2-a3b4-1c5d-6e7f-8g9h0i1j2k3l',
            year: 2021,
            value: '11000',
          },
          {
            dataID: 'x0y1z2a3-b4c5-2d6e-7f8g-9h0i1j2k3l4m',
            year: 2022,
            value: '12000',
          },
        ],
      },
      {
        countryID: 'q2r4w5u8-9s8s-1v0t-6r5w-0r4s2v6t5u3r',
        countryName: 'Slovakia',
        countryISO: 'SVK',
        years: [
          {
            dataID: 'y1z2a3b4-c5d6-3e7f-8g9h-0i1j2k3l4m5n',
            year: 2020,
            value: '7000',
          },
          {
            dataID: 'z2a3b4c5-d6e7-4f8g-9h0i-1j2k3l4m5n6o',
            year: 2021,
            value: '7500',
          },
          {
            dataID: 'a3b4c5d6-e7f8-5g9h-0i1j-2k3l4m5n6o7p',
            year: 2022,
            value: '8000',
          },
        ],
      },
      {
        countryID: 'r3s5x6v9-0t9t-2w1u-7s6x-1s5t3w7u6v4s',
        countryName: 'Romania',
        countryISO: 'ROU',
        years: [
          {
            dataID: 'b4c5d6e7-f8g9-6h0i-1j2k-3l4m5n6o7p8q',
            year: 2020,
            value: '6000',
          },
          {
            dataID: 'c5d6e7f8-g9h0-7i1j-2k3l-4m5n6o7p8q9r',
            year: 2021,
            value: '6500',
          },
          {
            dataID: 'd6e7f8g9-h0i1-8j2k-3l4m-5n6o7p8q9r0s',
            year: 2022,
            value: '7000',
          },
        ],
      },
      {
        countryID: 's4t6y7w0-1u0u-3x2v-8t7y-2t6u4x8v7w5t',
        countryName: 'Bulgaria',
        countryISO: 'BGR',
        years: [
          {
            dataID: 'e7f8g9h0-i1j2-9k3l-4m5n-6o7p8q9r0s1t',
            year: 2020,
            value: '5000',
          },
          {
            dataID: 'f8g9h0i1-j2k3-0l4m-5n6o-7p8q9r0s1t2u',
            year: 2021,
            value: '5500',
          },
          {
            dataID: 'g9h0i1j2-k3l4-1m5n-6o7p-8q9r0s1t2u3v',
            year: 2022,
            value: '6000',
          },
        ],
      },
      {
        countryID: 't5u7z8x1-2v1v-4y3w-9u8z-3u7v5y9w8x6u',
        countryName: 'Croatia',
        countryISO: 'HRV',
        years: [
          {
            dataID: 'h0i1j2k3-l4m5-2n6o-7p8q-9r0s1t2u3v4w',
            year: 2020,
            value: '7000',
          },
          {
            dataID: 'i1j2k3l4-m5n6-3o7p-8q9r-0s1t2u3v4w5x',
            year: 2021,
            value: '7500',
          },
          {
            dataID: 'j2k3l4m5-n6o7-4p8q-9r0s-1t2u3v4w5x6y',
            year: 2022,
            value: '8000',
          },
        ],
      },
    ];
  }
}
