import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { SurveyModel } from '../survey-table/models/survey-model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl, FormGroup } from '@angular/forms';
import moment from 'moment';
import { AntiMemLeak } from 'src/app/core/form-utils/anti-mem-leak/anti-mem-leak';
import {
  debounceTime,
  distinctUntilChanged,
  Observable,
  startWith,
} from 'rxjs';
import { ProviderService } from 'src/app/core/provider.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-validation-module-table',
  templateUrl: './validation-module-table.component.html',
  styleUrls: ['./validation-module-table.component.scss'],
})
// eslint-disable-next-line prettier/prettier
export class ValidationModuleTableComponent extends AntiMemLeak implements AfterViewInit {
  @Input({ required: true }) selectedSurvey!: SurveyModel;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false })
  sort!: MatSort;

  loading = true;
  countLoading = true;
  tableCount = 0;
  selectedCountries: string[] = [];
  selectedCodes: string[] = [];
  dataSource = new MatTableDataSource<any>();
  displayedColumns = ['countryName'];

  filterFormGroup = new FormGroup({
    percentageChange: new FormControl(50),
    yearFrom: new FormControl(moment().year() - 3),
    yearTo: new FormControl(moment().year()),
  });

  constructor(
    private providerService: ProviderService,
    private snackBar: MatSnackBar
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.filterFormGroup.controls.yearFrom.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(100))
        .subscribe((_value) => {
          this.paginator.pageIndex = 0;
          this.getTableData();
        })
    );
    this.subscriptions.add(
      this.filterFormGroup.controls.yearTo.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(100))
        .subscribe((_value) => {
          this.paginator.pageIndex = 0;
          this.getTableData();
        })
    );
    this.subscriptions.add(
      this.paginator.page.pipe(startWith(null)).subscribe((value) => {
        if (value) {
          this.getTableData(true);
        }
      })
    );
    this.subscriptions.add(
      this.sort.sortChange.subscribe(() => {
        this.paginator.pageIndex = 0;
        this.getTableData(true);
      })
    );
    this.getTableData();
  }

  setSelectedCodes(codes: string[]): void {
    this.selectedCodes = codes;
    this.getTableData();
  }

  setSelectedCountries(countries: string[]): void {
    this.selectedCountries = countries;
    this.getTableData();
  }

  enforceMinMax(el: any): void {
    const srcElement = el.srcElement;
    if (srcElement.value != '') {
      if (parseInt(srcElement.value) < parseInt(srcElement.min)) {
        srcElement.value = srcElement.min;
      }
      if (parseInt(srcElement.value) > parseInt(srcElement.max)) {
        srcElement.value = srcElement.max;
      }
    }
  }

  private async getTableCount(): Promise<void> {
    this.countLoading = true;
    try {
      this.tableCount =
        await this.providerService.validationModuleService.getValidationModuleTableCount(
          this.selectedSurvey.surveyID,
          this.selectedCountries,
          this.selectedCodes,
          this.filterFormGroup.controls.yearFrom.value ?? moment().year() - 3,
          this.filterFormGroup.controls.yearTo.value ?? moment().year()
        );
    } catch (e) {
      console.error(e);
      this.snackBar.open(
        'An error occured while retrieving the country list',
        'X',
        {
          duration: 3000,
          panelClass: ['error-snackbar'],
        }
      );
    } finally {
      this.countLoading = false;
    }
  }

  private async getTableData(skipCount = false): Promise<void> {
    this.loading = true;
    try {
      if (!skipCount) {
        this.getTableCount();
      }
      const backendData =
        await this.providerService.validationModuleService.getValidationModuleTableList(
          this.selectedSurvey.surveyID,
          this.selectedCountries,
          this.selectedCodes,
          this.filterFormGroup.controls.yearFrom.value ?? moment().year() - 3,
          this.filterFormGroup.controls.yearTo.value ?? moment().year(),
          this.sort && this.sort.direction.toUpperCase() !== ''
            ? this.sort.active ?? 'code'
            : 'code',
          this.sort && this.sort.direction.toUpperCase() !== ''
            ? this.sort.direction.toUpperCase()
            : 'ASC',
          this.paginator.pageIndex,
          this.paginator.pageSize
        );
      this.setDatasourceData(backendData);
    } catch (e) {
      console.error(e);
      this.snackBar.open(
        'An error occured while retrieving the country list',
        'X',
        {
          duration: 3000,
          panelClass: ['error-snackbar'],
        }
      );
    } finally {
      this.loading = false;
    }
  }

  private setDatasourceData(data: any): void {
    this.dataSource.data = data;
  }
}
