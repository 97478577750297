import { Injectable } from '@angular/core';
import { NetworkService } from '../../../core/net-utils/network.service';
import { CountryValidationModel } from '../models/country-validation-model';
import { CellHistoryModel } from '../../update-country-table/models/update-country-table-models';

@Injectable({
  providedIn: 'root',
})
export class CountryValidationTableService {
  private networkService!: NetworkService;

  setNetworkService(networkService: NetworkService): void {
    this.networkService = networkService;
  }

  async getCountryValidationTableCount(
    _surveyID: string,
    _countryID: string,
    _code: string[],
    _fromYear: number,
    _toYear: number
  ): Promise<number> {
    console.log('Getting country validation count', {
      surveyID: _surveyID,
      countryID: _countryID,
      code: _code,
      fromYear: _fromYear,
      toYear: _toYear,
    });
    return 10;
  }
  async getCountryValidationTableList(
    _surveyID: string,
    _countryID: string,
    _code: string[],
    _fromYear: number,
    _toYear: number,
    _sortCol: string,
    _sortType: string,
    _pageNumber: number,
    _pageSize: number
  ): Promise<CountryValidationModel[]> {
    return [
      {
        codeID: '1',
        codeName: 'i18n',
        years: [
          {
            dataID: '8f47e8c1-2b1a-4e5c-9d8f-3a7b5e9c8d6a',
            year: 2020,
            originalValue: undefined,
            newValue: 10000,
            originalNotes: undefined,
            newNotes: 'note',
            originalSource: undefined,
            newSource: 'source',
          },
          {
            dataID: 'a1b2c3d4-e5f6-4g7h-8i9j-0k1l2m3n4o5p',
            year: 2021,
            originalValue: 10000,
            newValue: 10000,
            originalNotes: 'note',
            newNotes: 'note',
            originalSource: 'source',
            newSource: 'source',
          },
          {
            dataID: 'b2c3d4e5-f6g7-4h8i-9j0k-1l2m3n4o5p6q',
            year: 2022,
            originalValue: 10000,
            newValue: undefined,
            originalNotes: 'note',
            newNotes: undefined,
            originalSource: 'source',
            newSource: undefined,
          },
        ],
      },
      {
        codeID: '2',
        codeName: 'i19n',
        years: [
          {
            dataID: 'c3d4e5f6-g7h8-4i9j-0k1l-2m3n4o5p6q7r',
            year: 2020,
            originalValue: undefined,
            newValue: 10000,
            originalNotes: undefined,
            newNotes: 'note',
            originalSource: undefined,
            newSource: 'source',
          },
          {
            dataID: 'd4e5f6g7-h8i9-4j0k-1l2m-3n4o5p6q7r8s',
            year: 2021,
            originalValue: 10000,
            newValue: 10000,
            originalNotes: 'note',
            newNotes: 'note',
            originalSource: 'source',
            newSource: 'source',
          },
          {
            dataID: 'e5f6g7h8-i9j0-4k1l-2m3n-4o5p6q7r8s9t',
            year: 2022,
            originalValue: 10000,
            newValue: undefined,
            originalNotes: 'note',
            newNotes: undefined,
            originalSource: 'source',
            newSource: undefined,
          },
        ],
      },
      {
        codeID: '3',
        codeName: 'i20n',
        years: [
          {
            dataID: 'f6g7h8i9-j0k1-4l2m-3n4o-5p6q7r8s9t0u',
            year: 2020,
            originalValue: undefined,
            newValue: 10000,
            originalNotes: undefined,
            newNotes: 'note',
            originalSource: undefined,
            newSource: 'source',
          },
          {
            dataID: 'g7h8i9j0-k1l2-4m3n-4o5p-6q7r8s9t0u1v',
            year: 2021,
            originalValue: 10000,
            newValue: 10000,
            originalNotes: 'note',
            newNotes: 'note',
            originalSource: 'source',
            newSource: 'source',
          },
          {
            dataID: 'h8i9j0k1-l2m3-4n4o-5p6q-7r8s9t0u1v2w',
            year: 2022,
            originalValue: 10000,
            newValue: undefined,
            originalNotes: 'note',
            newNotes: undefined,
            originalSource: 'source',
            newSource: undefined,
          },
        ],
      },
      {
        codeID: '4',
        codeName: 'i21n',
        years: [
          {
            dataID: 'i9j0k1l2-m3n4-4o5p-6q7r-8s9t0u1v2w3x',
            year: 2020,
            originalValue: undefined,
            newValue: 10000,
            originalNotes: undefined,
            newNotes: 'note',
            originalSource: undefined,
            newSource: 'source',
          },
          {
            dataID: 'j0k1l2m3-n4o5-4p6q-7r8s-9t0u1v2w3x4y',
            year: 2021,
            originalValue: 10000,
            newValue: 10000,
            originalNotes: 'note',
            newNotes: 'note',
            originalSource: 'source',
            newSource: 'source',
          },
          {
            dataID: 'k1l2m3n4-o5p6-4q7r-8s9t-0u1v2w3x4y5z',
            year: 2022,
            originalValue: 10000,
            newValue: undefined,
            originalNotes: 'note',
            newNotes: undefined,
            originalSource: 'source',
            newSource: undefined,
          },
        ],
      },
      {
        codeID: '5',
        codeName: 'i22n',
        years: [
          {
            dataID: 'l2m3n4o5-p6q7-4r8s-9t0u-1v2w3x4y5z6a',
            year: 2020,
            originalValue: undefined,
            newValue: 10000,
            originalNotes: undefined,
            newNotes: 'note',
            originalSource: undefined,
            newSource: 'source',
          },
          {
            dataID: 'm3n4o5p6-q7r8-4s9t-0u1v-2w3x4y5z6a7b',
            year: 2021,
            originalValue: 10000,
            newValue: 10000,
            originalNotes: 'note',
            newNotes: 'note',
            originalSource: 'source',
            newSource: 'source',
          },
          {
            dataID: 'n4o5p6q7-r8s9-4t0u-1v2w-3x4y5z6a7b8c',
            year: 2022,
            originalValue: 10000,
            newValue: undefined,
            originalNotes: 'note',
            newNotes: undefined,
            originalSource: 'source',
            newSource: undefined,
          },
        ],
      },
      {
        codeID: '6',
        codeName: 'i23n',
        years: [
          {
            dataID: 'o5p6q7r8-s9t0-4u1v-2w3x-4y5z6a7b8c9d',
            year: 2020,
            originalValue: undefined,
            newValue: 10000,
            originalNotes: undefined,
            newNotes: 'note',
            originalSource: undefined,
            newSource: 'source',
          },
          {
            dataID: 'p6q7r8s9-t0u1-4v2w-3x4y-5z6a7b8c9d0e',
            year: 2021,
            originalValue: 10000,
            newValue: 10000,
            originalNotes: 'note',
            newNotes: 'note',
            originalSource: 'source',
            newSource: 'source',
          },
          {
            dataID: 'q7r8s9t0-u1v2-4w3x-4y5z-6a7b8c9d0e1f',
            year: 2022,
            originalValue: 10000,
            newValue: undefined,
            originalNotes: 'note',
            newNotes: undefined,
            originalSource: 'source',
            newSource: undefined,
          },
        ],
      },
      {
        codeID: '7',
        codeName: 'i24n',
        years: [
          {
            dataID: 'r8s9t0u1-v2w3-4x4y-5z6a-7b8c9d0e1f2g',
            year: 2020,
            originalValue: undefined,
            newValue: 10000,
            originalNotes: undefined,
            newNotes: 'note',
            originalSource: undefined,
            newSource: 'source',
          },
          {
            dataID: 's9t0u1v2-w3x4-4y5z-6a7b-8c9d0e1f2g3h',
            year: 2021,
            originalValue: 10000,
            newValue: 10000,
            originalNotes: 'note',
            newNotes: 'note',
            originalSource: 'source',
            newSource: 'source',
          },
          {
            dataID: 't0u1v2w3-x4y5-4z6a-7b8c-9d0e1f2g3h4i',
            year: 2022,
            originalValue: 10000,
            newValue: undefined,
            originalNotes: 'note',
            newNotes: undefined,
            originalSource: 'source',
            newSource: undefined,
          },
        ],
      },
      {
        codeID: '8',
        codeName: 'i25n',
        years: [
          {
            dataID: 'u1v2w3x4-y5z6-4a7b-8c9d-0e1f2g3h4i5j',
            year: 2020,
            originalValue: undefined,
            newValue: 15000,
            originalNotes: undefined,
            newNotes: 'new note',
            originalSource: undefined,
            newSource: 'new source',
          },
          {
            dataID: 'v2w3x4y5-z6a7-4b8c-9d0e-1f2g3h4i5j6k',
            year: 2021,
            originalValue: 15000,
            newValue: 12000,
            originalNotes: 'old note',
            newNotes: 'updated note',
            originalSource: 'old source',
            newSource: 'updated source',
          },
          {
            dataID: 'w3x4y5z6-a7b8-4c9d-0e1f-2g3h4i5j6k7l',
            year: 2022,
            originalValue: 12000,
            newValue: undefined,
            originalNotes: 'note',
            newNotes: undefined,
            originalSource: 'source',
            newSource: undefined,
          },
        ],
      },
      {
        codeID: '9',
        codeName: 'i26n',
        years: [
          {
            dataID: 'x4y5z6a7-b8c9-4d0e-1f2g-3h4i5j6k7l8m',
            year: 2020,
            originalValue: undefined,
            newValue: 8000,
            originalNotes: undefined,
            newNotes: 'initial note',
            originalSource: undefined,
            newSource: 'initial source',
          },
          {
            dataID: 'y5z6a7b8-c9d0-4e1f-2g3h-4i5j6k7l8m9n',
            year: 2021,
            originalValue: 8000,
            newValue: 9500,
            originalNotes: 'first note',
            newNotes: 'revised note',
            originalSource: 'first source',
            newSource: 'revised source',
          },
          {
            dataID: 'z6a7b8c9-d0e1-4f2g-3h4i-5j6k7l8m9n0o',
            year: 2022,
            originalValue: 9500,
            newValue: undefined,
            originalNotes: 'note',
            newNotes: undefined,
            originalSource: 'source',
            newSource: undefined,
          },
        ],
      },
      {
        codeID: '10',
        codeName: 'i27n',
        years: [
          {
            dataID: 'a7b8c9d0-e1f2-4g3h-4i5j-6k7l8m9n0o1p',
            year: 2020,
            originalValue: undefined,
            newValue: 11000,
            originalNotes: undefined,
            newNotes: 'first entry',
            originalSource: undefined,
            newSource: 'primary source',
          },
          {
            dataID: 'b8c9d0e1-f2g3-4h4i-5j6k-7l8m9n0o1p2q',
            year: 2021,
            originalValue: 11000,
            newValue: 13500,
            originalNotes: 'initial note',
            newNotes: 'updated entry',
            originalSource: 'initial source',
            newSource: 'verified source',
          },
          {
            dataID: 'c9d0e1f2-g3h4-4i5j-6k7l-8m9n0o1p2q3r',
            year: 2022,
            originalValue: 13500,
            newValue: undefined,
            originalNotes: 'latest note',
            newNotes: undefined,
            originalSource: 'latest source',
            newSource: undefined,
          },
        ],
      },
    ];
  }

  getCellHistory(
    _cellID: string
  ): CellHistoryModel[] | PromiseLike<CellHistoryModel[]> {
    return [
      {
        value: '10000',
        user: 'John Smith',
        lastModifiedOn: '2023-01-15',
        version: 2,
      },
      {
        value: '12500',
        user: 'Jane Doe',
        lastModifiedOn: '2023-03-22',
        version: 3,
      },
      {
        value: '15000',
        user: 'Bob Wilson',
        lastModifiedOn: '2023-06-30',
        version: 4,
      },
      {
        value: '13750',
        user: 'Alice Brown',
        lastModifiedOn: '2023-09-12',
        version: 5,
      },
    ];
  }

  async downloadPDF(_surveyId: string, _countries: string[]): Promise<void> {
    await this.delay(1000);
    return;
  }

  private delay(ms: number): Promise<any> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
