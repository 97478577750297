import { Injectable } from '@angular/core';
import { HierarchyTreeModel } from '../models/hierarchy-tree-model';
import { NetworkService } from 'src/app/core/net-utils/network.service';

@Injectable({
  providedIn: 'root',
})
export class HierarchyTreeService {
  networkService!: NetworkService;

  setNetworkService(networkService: NetworkService): void {
    this.networkService = networkService;
  }

  constructor() {}

  async getHierarchyData(): Promise<HierarchyTreeModel[]> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const data: HierarchyTreeModel[] = Array.from(
          { length: 40 },
          (_, i) => ({
            id: (i + 1).toString(),
            code: `Child ${i + 1}`,
            selected: false,
            children: [
              {
                id: (i * 2 + 41).toString(),
                code: `Grandchild ${i * 2 + 1}`,
                selected: false,
              },
              {
                id: (i * 2 + 42).toString(),
                code: `Grandchild ${i * 2 + 2}`,
                selected: false,
              },
            ],
          })
        );
        resolve(data);
      }, 1000);
    });
  }
}
