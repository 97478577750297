import { Injectable } from '@angular/core';
import { NetworkService } from '../../../core/net-utils/network.service';
import { SurveyModel } from '../models/survey-model';

@Injectable({
  providedIn: 'root',
})
export class SurveyTableService {
  private networkService!: NetworkService;

  setNetworkService(networkService: NetworkService): void {
    this.networkService = networkService;
  }

  async getSurveyListCount(
    _surveyName?: string,
    _surveyDescription?: string,
    _surveyYear?: string
  ): Promise<number> {
    return 42;
  }

  async getSurveyList(
    pageIndex: number,
    pageSize: number,
    sortCol: string,
    sortType: string,
    surveyName?: string,
    surveyDescription?: string,
    surveyYear?: number
  ): Promise<SurveyModel[]> {
    let surveys = [
      {
        surveyID: 'f87bcced-bb75-4070-bd13-d73f3a938bb2',
        surveyName: 'ITU ICT Price Basket Questionnaire 2024',
        surveyDescription: 'ITU ICT Price Basket  2024',
        surveyYear: 2024,
      },
      {
        surveyID: '0869f8b3-3fb0-435f-aad9-629085557fa8',
        surveyName: 'ITU ICT Households Long Questionnaire 2024',
        surveyDescription: 'ITU ICT Households Long Questionnaire 2024',
        surveyYear: 2024,
      },
      {
        surveyID: '258c4300-b802-45bf-8844-eab997910f41',
        surveyName: 'ITU Survey on Tariff Policies 2024',
        surveyDescription: 'Questionnaire on Tariff Policies - Countries 2024',
        surveyYear: 2024,
      },
      {
        surveyID: 'a4cb01db-990f-4a4c-9798-b0710a2dad76',
        surveyName: 'ITU ICT Households data 2024 - for verification (DataHub)',
        surveyDescription: 'Household data 2024',
        surveyYear: 2024,
      },
      {
        surveyID: '821da2cd-e349-43a2-8b9a-ed40487927bc',
        surveyName: 'ITU ICT Households data 2024 - for verification (IDI)',
        surveyDescription: 'Household data 2024',
        surveyYear: 2024,
      },
      {
        surveyID: '50342d7e-e797-45e3-880c-6b3be17a7ba8',
        surveyName:
          'ITU World Telecommunication/ICT Indicators Long Questionnaire 2024',
        surveyDescription:
          'Telecommunication/ICT Indicators Questionnaire 2024. Dummy Questionnaire, actual in Qualtrics.',
        surveyYear: 2023,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
      {
        surveyID: 'dc5f7147-bfdf-4d96-bdb6-4a67ad09fbe9',
        surveyName: 'IDI2023 WTI indicators - for verification',
        surveyDescription:
          'Questionnaire to verify WTI indicators (DataYear 2021) to be used in the IDI 2023, conducted in Qualtrics. This Survey in ICT Eye is blank, it was created to obtain a SurveyID. Dummy Questionnaire, actual in Qualtrics.IDI2023 WTI indicators - for verifica',
        surveyYear: 2024,
      },
    ];

    // Apply filters if provided
    if (surveyName) {
      surveys = surveys.filter((s) =>
        s.surveyName.toLowerCase().includes(surveyName.toLowerCase())
      );
    }
    if (surveyDescription) {
      surveys = surveys.filter((s) =>
        s.surveyDescription
          .toLowerCase()
          .includes(surveyDescription.toLowerCase())
      );
    }
    if (surveyYear) {
      surveys = surveys.filter((s) => s.surveyYear === surveyYear);
    }

    // Apply sorting
    if (sortCol && sortType) {
      surveys.sort((a, b) => {
        const aValue = a[sortCol as keyof SurveyModel];
        const bValue = b[sortCol as keyof SurveyModel];

        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return sortType === 'asc'
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        }

        return sortType === 'asc'
          ? aValue < bValue
            ? -1
            : 1
          : bValue < aValue
          ? -1
          : 1;
      });
    }

    // Apply pagination
    const start = pageIndex * pageSize;
    const end = start + pageSize;
    return surveys.slice(start, end);
  }
}
