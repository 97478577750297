@if (loading) {
<app-loader></app-loader>
}

<div class="filters-class">
  <ng-container>
    <div class="filters-bar" [formGroup]="filterSurveyFormGroup">
      <div class="first-column">
        <span class="mat-drop-down">
          <mat-label>{{ 'SURVEY_TABLE.NAME_LABEL' | translate }}</mat-label>
          <input matInput type="text" formControlName="surveyName">
        </span>
        <span class="mat-drop-down">
          <mat-label>{{ 'SURVEY_TABLE.DESCRIPTION_LABEL' | translate }}</mat-label>
          <input matInput type="text" formControlName="surveyDescription">
        </span>
        <span class="mat-drop-down">
          <mat-label>{{ 'SURVEY_TABLE.YEAR_LABEL' | translate }}</mat-label>
          <input matInput type="number" formControlName="surveyYear">
        </span>
      </div>
    </div>
  </ng-container>
</div>
<div class="table-holder mat-elevation-z8" tabindex="0">
  <table mat-table matSort [dataSource]="dataSource">
    <ng-container matColumnDef="surveyName">
      <th mat-header-cell *matHeaderCellDef appResizeColumn>
        <div mat-sort-header>{{ 'SURVEY_TABLE.NAME_HEADER' | translate }}</div>
      </th>
      <td mat-cell *matCellDef="let element" (click)="selectSurvey(element)">
        <span class="row-line">
          {{ element.surveyName }}
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="surveyDescription">
      <th mat-header-cell *matHeaderCellDef appResizeColumn>
        <div mat-sort-header>{{ 'SURVEY_TABLE.DESCRIPTION_HEADER' | translate }}</div>
      </th>
      <td mat-cell *matCellDef="let element" (click)="selectSurvey(element)">
        <span class="row-line">
          {{ element.surveyDescription }}
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="surveyYear">
      <th mat-header-cell *matHeaderCellDef appResizeColumn>
        <div mat-sort-header>{{ 'SURVEY_TABLE.YEAR_HEADER' | translate }}</div>
      </th>
      <td mat-cell *matCellDef="let element" (click)="selectSurvey(element)">
        <span class="row-line">
          {{ element.surveyYear }}
        </span>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="mat-header-row"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row clickable"></tr>
  </table>
</div>
<div class="paginator">
  <mat-paginator class="mat-elevation-z8 hide-ripple" [length]="tableCount" [pageSizeOptions]="[50, 100, 150]">
  </mat-paginator>
</div>