import { Injectable } from '@angular/core';
import { NetworkService } from '../../../core/net-utils/network.service';
import { CountrySurveyModel } from '../models/country-survey-model';
import { SurveyStatusEnum } from '../enums/survey-status-enum';
import { SurveyValidationStatusEnum } from '../enums/survey-validation-status-enum';

@Injectable({
  providedIn: 'root',
})
export class SurveyCountryTableService {
  private networkService!: NetworkService;
  setNetworkService(networkService: NetworkService): void {
    this.networkService = networkService;
  }

  async getCountryList(_surveyID: string): Promise<CountrySurveyModel[]> {
    return [
      {
        countryID: 'c1cf51d1-52c1-466b-a7b5-0e30dfb2cd60',
        countryName: 'Aruba',
        isoCode: 'ABW',
        surveyStatus: SurveyStatusEnum.sent,
        validationStatus: SurveyValidationStatusEnum.completed,
      },
      {
        countryID: '11c34d27-7f99-4aff-a660-58936e655344',
        countryName: 'Afghanistan',
        isoCode: 'AFG',
        surveyStatus: SurveyStatusEnum.sent,
        validationStatus: SurveyValidationStatusEnum.pending,
      },
      {
        countryID: '1aeab7bb-edaf-40e4-86f7-236ad524597f',
        countryName: 'South Africa',
        isoCode: 'ZAF',
        surveyStatus: SurveyStatusEnum.incomplete,
        validationStatus: SurveyValidationStatusEnum.notStarted,
      },
      {
        countryID: 'fa31b57b-24e3-4def-98ca-f6ceb06a4bd5',
        countryName: 'Angola',
        isoCode: 'AGO',
        surveyStatus: SurveyStatusEnum.incomplete,
        validationStatus: SurveyValidationStatusEnum.notStarted,
      },
      {
        countryID: '386b871c-2940-4b7c-b7af-25e680cf36ab',
        countryName: 'Anguilla',
        isoCode: 'AIA',
        surveyStatus: SurveyStatusEnum.sent,
        validationStatus: SurveyValidationStatusEnum.completed,
      },
      {
        countryID: '33618e9b-2a38-41f5-ab90-e88dcbfe17b4',
        countryName: 'Albania',
        isoCode: 'ALB',
        surveyStatus: SurveyStatusEnum.sent,
        validationStatus: SurveyValidationStatusEnum.pending,
      },
      {
        countryID: 'dbc7b428-266b-436e-a856-5c5d82cba270',
        countryName: 'Algeria',
        isoCode: 'DZA',
        surveyStatus: SurveyStatusEnum.sent,
        validationStatus: SurveyValidationStatusEnum.completed,
      },
      {
        countryID: '242b5d26-ba89-4ab4-a937-7d61ec002000',
        countryName: 'Andorra',
        isoCode: 'AND',
        surveyStatus: SurveyStatusEnum.sent,
        validationStatus: SurveyValidationStatusEnum.completed,
      },
      {
        countryID: '060d976e-fecf-4a02-bf9d-7b8c60e363c7',
        countryName: 'Western Sahara',
        isoCode: 'ESH',
        surveyStatus: SurveyStatusEnum.sent,
        validationStatus: SurveyValidationStatusEnum.completed,
      },
      {
        countryID: 'e13f3550-a69b-48f0-8355-35ed9ac8824f',
        countryName: 'Argentina',
        isoCode: 'ARG',
        surveyStatus: SurveyStatusEnum.sent,
        validationStatus: SurveyValidationStatusEnum.completed,
      },
      {
        countryID: '010f1d00-0736-4e3b-8562-c5f37212b83d',
        countryName: 'Armenia',
        isoCode: 'ARM',
        surveyStatus: SurveyStatusEnum.sent,
        validationStatus: SurveyValidationStatusEnum.completed,
      },
      {
        countryID: '94ebdfe1-03eb-43bb-8592-1ea04d91323d',
        countryName: 'Saudi Arabia',
        isoCode: 'SAU',
        surveyStatus: SurveyStatusEnum.sent,
        validationStatus: SurveyValidationStatusEnum.completed,
      },
      {
        countryID: 'f25d599a-4039-48a1-a2af-a3acb70a30d9',
        countryName: 'Antigua and Barbuda',
        isoCode: 'ATG',
        surveyStatus: SurveyStatusEnum.sent,
        validationStatus: SurveyValidationStatusEnum.completed,
      },
      {
        countryID: 'd4ac4a55-78f3-4125-8973-f7dcedfa2087',
        countryName: 'Neth. Antilles',
        isoCode: 'ANT',
        surveyStatus: SurveyStatusEnum.sent,
        validationStatus: SurveyValidationStatusEnum.completed,
      },
      {
        countryID: 'c813d0d0-3fac-41bf-aa27-5ae970cad600',
        countryName: 'Australia',
        isoCode: 'AUS',
        surveyStatus: SurveyStatusEnum.sent,
        validationStatus: SurveyValidationStatusEnum.completed,
      },
      {
        countryID: 'ac9c60fa-1356-4f7a-b13d-c930cf912fb7',
        countryName: 'Austria',
        isoCode: 'AUT',
        surveyStatus: SurveyStatusEnum.sent,
        validationStatus: SurveyValidationStatusEnum.completed,
      },
      {
        countryID: '506ea889-4229-4124-81fc-95fe744fcc38',
        countryName: 'Azerbaijan',
        isoCode: 'AZE',
        surveyStatus: SurveyStatusEnum.sent,
        validationStatus: SurveyValidationStatusEnum.completed,
      },
      {
        countryID: 'edcc8af0-4937-4eb6-8822-4c991d636d52',
        countryName: 'Brazil',
        isoCode: 'BRA',
        surveyStatus: SurveyStatusEnum.sent,
        validationStatus: SurveyValidationStatusEnum.completed,
      },
      {
        countryID: 'f834d309-a8a9-4867-947a-27a9090c9697',
        countryName: 'Bahamas',
        isoCode: 'BHS',
        surveyStatus: SurveyStatusEnum.sent,
        validationStatus: SurveyValidationStatusEnum.completed,
      },
      {
        countryID: 'eb128633-3eb8-4d87-af66-83a523c8efc6',
        countryName: 'Burundi',
        isoCode: 'BDI',
        surveyStatus: SurveyStatusEnum.sent,
        validationStatus: SurveyValidationStatusEnum.completed,
      },
      {
        countryID: 'dff48b3f-645a-492e-8a81-b933ec41e791',
        countryName: 'Belgium',
        isoCode: 'BEL',
        surveyStatus: SurveyStatusEnum.sent,
        validationStatus: SurveyValidationStatusEnum.completed,
      },
      {
        countryID: '1c7c5a61-8180-4850-8370-d8df23381fcc',
        countryName: 'Benin',
        isoCode: 'BEN',
        surveyStatus: SurveyStatusEnum.sent,
        validationStatus: SurveyValidationStatusEnum.completed,
      },
    ];
  }

  async startCountryValidation(
    _surveyID: string,
    _countryID: string
  ): Promise<void> {
    return;
  }
}
