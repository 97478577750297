@if (loading) {
  <app-loader></app-loader>
}
<div class="validation-module-container">
  <div class="left-side">
    <app-hierarchy-tree-view (codeSelectionChanged)="setSelectedCodes($event)"></app-hierarchy-tree-view>
  </div>
  <div class="right-side">
    <div class="filters-class">
      <div class="filters-bar" [formGroup]="filterFormGroup">
        <div class="first-column">
          <div class="mat-drop-down">
            <mat-label>{{ 'VALIDATION_MODULE_TABLE.PERCENTAGE_CHANGE' | translate }}</mat-label>
            <input class="short-number-input" (input)="enforceMinMax($event)" type="number" min="0" max="200" formControlName="percentageChange">
          </div>
          <div class="mat-drop-down">
            <mat-label>{{ 'VALIDATION_MODULE_TABLE.YEAR_FROM' | translate }}</mat-label>
            <input matInput type="number" formControlName="yearFrom">
          </div>
          <div class="mat-drop-down">
            <mat-label>{{ 'VALIDATION_MODULE_TABLE.YEAR_TO' | translate }}</mat-label>
            <input matInput type="number" formControlName="yearTo">
          </div>
          <div class="mat-drop-down">
            <app-region-country-filter (countriesChanged)="setSelectedCountries($event)" styleType="filters-bar"></app-region-country-filter>
          </div>
        </div>
      </div>
    </div>
    <div class="table-holder mat-elevation-z8" tabindex="0">
      <table mat-table matSort [dataSource]="dataSource">
        <ng-container matColumnDef="countryName">
          <th mat-header-cell *matHeaderCellDef appResizeColumn>
            <div mat-sort-header>{{ 'VALIDATION_MODULE_TABLE.COUNTRY_NAME_HEADER' | translate }}</div>
          </th>
          <td mat-cell *matCellDef="let element">
              <span class="row-line">
                {{ element.countryName }}
              </span>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="mat-header-row"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
      </table>
    </div>
    <div class="paginator">
      <mat-paginator class="mat-elevation-z8 hide-ripple"
                     [length]="tableCount"
                     [pageSizeOptions]="[50, 100, 150]">
      </mat-paginator>
    </div>
  </div>
</div>
