import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SurveyModel } from '../survey-table/models/survey-model';
import { CountrySurveyModel } from '../survey-country-table/models/country-survey-model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ProviderService } from '../../core/provider.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { DataTypeEnum } from './enum/data-type-enum';
import { AntiMemLeak } from '../../core/form-utils/anti-mem-leak/anti-mem-leak';
import { distinctUntilChanged, startWith } from 'rxjs/operators';
import { debounceTime } from 'rxjs';
import { MatChipInputEvent } from '@angular/material/chips';
import { CountryValidationModel } from './models/country-validation-model';
import { MatDialog } from '@angular/material/dialog';
import { CustomDownloadSnackbarComponent } from '../dialogues/snackbars/custom-download-snackbar/custom-download-snackbar.component';
import { CellHistoryModel } from '../update-country-table/models/update-country-table-models';

@Component({
  selector: 'app-country-validation-table',
  templateUrl: './country-validation-table.component.html',
  styleUrl: './country-validation-table.component.scss',
})
// eslint-disable-next-line prettier/prettier
export class CountryValidationTableComponent extends AntiMemLeak implements OnInit, AfterViewInit {
  @Input({ required: true }) selectedSurvey!: SurveyModel;
  @Input({ required: true }) selectedCountry!: CountrySurveyModel;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false })
  sort!: MatSort;
  @ViewChild('chipsFilterInputElement')
  chipsFilterInputElement!: ElementRef;
  loading = true;
  countLoading = true;
  filterCountryFormGroup = new FormGroup({
    yearFrom: new FormControl(moment().year() - 3),
    yearTo: new FormControl(moment().year()),
  });
  dataSource!: MatTableDataSource<any>;
  displayedColumns: string[] = [];
  tableCount = 0;
  yearsToDisplay: number[] = [];
  filters: string[] = [];
  isRemovingChip = false;
  codeFilterEntered: any[] = [];
  separatorKeysCodes: number[] = [13, 32, 188];
  downloadingPDF = false;
  selectedCell?: { cellElement: any; columnDef: string };
  cellHistory: CellHistoryModel[] = [];
  private downloadDialog: any;
  private removeChipInterval?: any;
  private removeChipTimeout?: any;

  constructor(
    private providerService: ProviderService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    super();
    this.dataSource = new MatTableDataSource<any>();
  }

  ngOnInit(): void {
    this.initiateTable();
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.filterCountryFormGroup.controls.yearFrom.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(100))
        .subscribe((_value) => {
          this.paginator.pageIndex = 0;
          this.getTableData();
        })
    );
    this.subscriptions.add(
      this.filterCountryFormGroup.controls.yearTo.valueChanges
        .pipe(distinctUntilChanged(), debounceTime(100))
        .subscribe((_value) => {
          this.paginator.pageIndex = 0;
          this.getTableData();
        })
    );
    this.subscriptions.add(
      this.paginator.page.pipe(startWith(null)).subscribe((value) => {
        if (value) {
          this.getTableData(true);
        }
      })
    );
    this.subscriptions.add(
      this.sort.sortChange.subscribe(() => {
        this.paginator.pageIndex = 0;
        this.getTableData(true);
      })
    );
  }

  selectCell(element: any, columnDef: string): void {
    this.selectedCell = {
      cellElement: element,
      columnDef: columnDef,
    };
    this.getCellHistory(element['dataID']);
  }

  getCellBorder(element: any, columnDef: string): string {
    if (this.selectedCell) {
      if (
        this.selectedCell.cellElement === element &&
        this.selectedCell.columnDef === columnDef
      ) {
        return 'border: 1px solid blue';
      }
    }
    return '';
  }

  removeChip(item: any): void {
    this.isRemovingChip = true;
    if (this.removeChipTimeout) {
      clearTimeout(this.removeChipTimeout);
    }
    const index = this.filters.indexOf(item);
    const codeFilterIndex = this.codeFilterEntered.indexOf(item);
    if (index >= 0) {
      this.filters.splice(index, 1);
    }
    if (codeFilterIndex >= 0) {
      this.codeFilterEntered.splice(codeFilterIndex, 1);
    }
    if (this.removeChipInterval === undefined) {
      this.removeChipInterval = setInterval(() => {
        if (!this.isRemovingChip) {
          this.getTableData();
          clearInterval(this.removeChipInterval);
          this.removeChipInterval = undefined;
        }
      }, 550);
    }
    this.removeChipTimeout = setTimeout(() => {
      this.isRemovingChip = false;
    }, 500);
  }

  addChipValue(event: MatChipInputEvent): void {
    const value = event.value;
    if ((value || '').trim()) {
      this.addChip(event.value);
    }
  }

  addChip(value: string): void {
    if ((value || '').trim()) {
      if (value.includes(',')) {
        this.filters.push(...value.split(','));
        this.codeFilterEntered.push(...value.split(','));
      } else {
        this.filters.push(value.trim());
        this.codeFilterEntered.push(value.trim());
      }
    }
    this.chipsFilterInputElement.nativeElement.value = '';
    this.chipsFilterInputElement.nativeElement.focus();
    this.getTableData();
  }

  private async getTableCount(): Promise<void> {
    this.countLoading = true;
    try {
      this.tableCount =
        await this.providerService.countryValidationTableService.getCountryValidationTableCount(
          this.selectedSurvey.surveyID,
          this.selectedCountry.countryID,
          this.codeFilterEntered,
          this.filterCountryFormGroup.controls.yearFrom.value ??
            moment().year() - 3,
          this.filterCountryFormGroup.controls.yearTo.value ?? moment().year()
        );
    } catch (e) {
      console.error(e);
      this.snackBar.open(
        'An error occured while retrieving the country list',
        'X',
        {
          duration: 3000,
          panelClass: ['error-snackbar'],
        }
      );
    } finally {
      this.countLoading = false;
    }
  }

  private async initiateTable(): Promise<void> {
    this.loading = true;
    try {
      this.getTableCount();
      const backendData =
        await this.providerService.countryValidationTableService.getCountryValidationTableList(
          this.selectedSurvey.surveyID,
          this.selectedCountry.countryID,
          this.codeFilterEntered,
          this.filterCountryFormGroup.controls.yearFrom.value ??
            moment().year() - 3,
          this.filterCountryFormGroup.controls.yearTo.value ?? moment().year(),
          this.sort && this.sort.direction.toUpperCase() !== ''
            ? this.sort.active ?? 'code'
            : 'code',
          this.sort && this.sort.direction.toUpperCase() !== ''
            ? this.sort.direction.toUpperCase()
            : 'ASC',
          this.paginator ? this.paginator.pageIndex : 0,
          this.paginator ? this.paginator.pageSize : 0
        );
      this.setDatasourceData(backendData);
    } catch (e) {
      console.error(e);
      this.snackBar.open(
        'An error occured while retrieving the country list',
        'X',
        {
          duration: 3000,
          panelClass: ['error-snackbar'],
        }
      );
    } finally {
      this.loading = false;
    }
  }

  private async getCellHistory(cellID: string): Promise<void> {
    this.cellHistory = [];
    try {
      this.cellHistory =
        await this.providerService.countryValidationTableService.getCellHistory(
          cellID
        );
    } catch (e) {
      console.error(e);
      this.snackBar.open(
        'An error occured while retrieving the cell history',
        'X',
        {
          duration: 3000,
          panelClass: ['error-snackbar'],
        }
      );
    }
  }

  private async getTableData(skipCount = false): Promise<void> {
    this.loading = true;
    try {
      if (!skipCount) {
        this.getTableCount();
      }
      const backendData =
        await this.providerService.countryValidationTableService.getCountryValidationTableList(
          this.selectedSurvey.surveyID,
          this.selectedCountry.countryID,
          this.codeFilterEntered,
          this.filterCountryFormGroup.controls.yearFrom.value ??
            moment().year() - 3,
          this.filterCountryFormGroup.controls.yearTo.value ?? moment().year(),
          this.sort && this.sort.direction.toUpperCase() !== ''
            ? this.sort.active ?? 'code'
            : 'code',
          this.sort && this.sort.direction.toUpperCase() !== ''
            ? this.sort.direction.toUpperCase()
            : 'ASC',
          this.paginator.pageIndex,
          this.paginator.pageSize
        );
      this.setDatasourceData(backendData);
    } catch (e) {
      console.error(e);
      this.snackBar.open(
        'An error occured while retrieving the country list',
        'X',
        {
          duration: 3000,
          panelClass: ['error-snackbar'],
        }
      );
    } finally {
      this.loading = false;
    }
  }

  private setDatasourceData(backendData: CountryValidationModel[]): void {
    if (backendData.length > 0) {
      this.yearsToDisplay = backendData[0].years.map((value) => value.year);
      this.displayedColumns = [
        'rowDescription',
        ...this.yearsToDisplay.flatMap((year) => [
          `${year}-old`,
          `${year}-new`,
        ]),
      ];
    }
    const dataToShow = [];
    for (const backendElement of backendData) {
      let row: { [key: string]: any } = {};
      row['rowDescription'] = backendElement.codeName;
      for (const year of this.yearsToDisplay) {
        const backendDataYear = backendElement.years.find(
          (value) => value.year === year
        );
        row[`dataID`] = backendDataYear?.dataID;
        row[`${year}-old`] = backendDataYear?.originalValue;
        row[`${year}-new`] = backendDataYear?.newValue;
        if (backendDataYear?.originalValue !== backendDataYear?.newValue) {
          if (!backendDataYear?.originalValue && backendDataYear?.newValue) {
            row[`${year}-type`] = DataTypeEnum.NewData;
          } else if (
            !backendDataYear?.newValue &&
            backendDataYear?.originalValue
          ) {
            row[`${year}-type`] = DataTypeEnum.dataDeleted;
          } else {
            row[`${year}-type`] = DataTypeEnum.dataChanged;
          }
        } else {
          row[`${year}-type`] = DataTypeEnum.NoChange;
        }
      }
      dataToShow.push(row);
      row = {};
      row['rowDescription'] = 'notes';
      for (const year of this.yearsToDisplay) {
        const backendDataYear = backendElement.years.find(
          (value) => value.year === year
        );
        row[`dataID`] = backendDataYear?.dataID;
        row[`${year}-old`] = backendDataYear?.originalValue;
        row[`${year}-new`] = backendDataYear?.newValue;
        if (backendDataYear?.originalValue !== backendDataYear?.newValue) {
          if (!backendDataYear?.originalValue && backendDataYear?.newValue) {
            row[`${year}-type`] = DataTypeEnum.NewData;
          } else if (
            !backendDataYear?.newValue &&
            backendDataYear?.originalValue
          ) {
            row[`${year}-type`] = DataTypeEnum.dataDeleted;
          } else {
            row[`${year}-type`] = DataTypeEnum.dataChanged;
          }
        } else {
          row[`${year}-type`] = DataTypeEnum.NoChange;
        }
      }
      dataToShow.push(row);
      row = {};
      row['rowDescription'] = 'sources';
      for (const year of this.yearsToDisplay) {
        const backendDataYear = backendElement.years.find(
          (value) => value.year === year
        );
        row[`dataID`] = backendDataYear?.dataID;
        row[`${year}-old`] = backendDataYear?.originalValue;
        row[`${year}-new`] = backendDataYear?.newValue;
        if (backendDataYear?.originalValue !== backendDataYear?.newValue) {
          if (!backendDataYear?.originalValue && backendDataYear?.newValue) {
            row[`${year}-type`] = DataTypeEnum.NewData;
          } else if (
            !backendDataYear?.newValue &&
            backendDataYear?.originalValue
          ) {
            row[`${year}-type`] = DataTypeEnum.dataDeleted;
          } else {
            row[`${year}-type`] = DataTypeEnum.dataChanged;
          }
        } else {
          row[`${year}-type`] = DataTypeEnum.NoChange;
        }
      }
      dataToShow.push(row);
    }
    this.dataSource.data = dataToShow;
  }

  showDownloadPDFDialog(dialogRef: any): void {
    this.downloadDialog = this.dialog.open(dialogRef, {
      width: '500px',
      disableClose: true,
      autoFocus: false,
    });
    this.downloadPDF();
  }

  async downloadPDF(): Promise<void> {
    this.downloadingPDF = true;
    try {
      await this.providerService.countryValidationTableService.downloadPDF(
        this.selectedSurvey.surveyID,
        [this.selectedCountry.isoCode]
      );
      this.downloadDialog.close();
      this.snackBar.openFromComponent(CustomDownloadSnackbarComponent, {
        duration: 10000,
        panelClass: ['custom-download-snackbar'],
      });
      this.downloadDialog?.close();
    } catch (e) {
      console.error(e);
      this.snackBar.open('An error occured while downloading the file', 'X', {
        duration: 3000,
        panelClass: ['error-snackbar'],
      });
    } finally {
      this.downloadingPDF = false;
    }
  }
}
